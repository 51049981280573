import React from 'react';
import TableCotizacionTbody from './TableCotizacionTbody';
import TableCotizacionThead from './TableCotizacionThead';

const TableCotizacion = (props) => {
  const { handleAprobar, handlePDF } = props.actions;
  const { loadingTables } = props.data;

  return (
    <div className="overflow-x-auto">
      {!loadingTables && (
        <table className="w-full table-auto text-sm">
          <TableCotizacionThead data={props.data} />
          <TableCotizacionTbody
            endPoint={props.endPoint}
            data={props.data}
            modals={props.modals}
            handleAprobar={handleAprobar}
            handlePDF={handlePDF}
          />
        </table>
      )}
    </div>
  );
};
export default TableCotizacion;
