import React, { useState, useEffect } from 'react';

// import Map from '../../../../../img/Map.jpg';

import { useApiClient, validateSuccess2xx } from '../../../../../clients/apiAuth';
import { CustomToast, ErrorToast, OkToast } from '../../../Toast';

import toast from 'react-hot-toast';

import 'react-loading-skeleton/dist/skeleton.css';
import { TableCotizacionTarifa } from '../ModalCotizacion/TableCotizacionTarifa';
import { store } from '../../../../../store';
import { setSelSolicitud, setSelTipoVehiculo } from '../../../../../actions';

import { TableTarifa } from '../ModalSeguimiento/TableTarifa';
import { ModalCotizacionTarifa } from './ModalCotizacionTarifa';

export const PanelCotizacionTarifa = ({ handleAddTarifa, handleChangeTarifaUnidad, data, isReadOnly = false, endPoints }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [isModalTarifaVisible, setIsModalTarifaVisible] = useState(false);

  const [solicitudDetalle, setSolicitudDetalle] = useState([]);

  const { get, post, put, DELETE } = useApiClient();

  const handleShowTarifaModal = (isVisible = true) => {
    setIsModalTarifaVisible(isVisible);
  };

  const deleteSolicitudDetalle = (item) => {
    const items = data.items;
    const newItems = items.filter((i) => i.ITEM_ID !== item.ITEM_ID);

    data.setItems(newItems);

    calculateTotals(newItems);
  };

  const calculateTotals = (items) => {
    let total = items.reduce((acc, item) => acc + item.SOLICITUD_DETALLE_TOTAL, 0);

    data.setTotal(total);
  };

  const handleDeleteDetalle = (item) => {
    toast((t) => (
      <span>
        <b>Está seguro que desea eliminar el registro?</b>
        <button
          className="p-4"
          onClick={() => {
            toast.dismiss(t.id);
            deleteSolicitudDetalle(item);
          }}>
          Si
        </button>
        <button onClick={() => toast.dismiss(t.id)}>No</button>
      </span>
    ));
  };

  // useEffect(() => {
  //   getSolicitudDetalle();
  // }, [data.selSolicitudVehiculoTipo.SOLICITUD_VEHICULO_TIPO_ID]);

  return (
    <>
      <TableCotizacionTarifa
        data={data}
        modals={{ handleShowTarifaModal }}
        actions={{ handleAddTarifa, handleChangeTarifaUnidad, handleDeleteDetalle }}
        isReadOnly={isReadOnly}></TableCotizacionTarifa>

      {/* <TableTarifaOld
        data={data}
        actions={{ handleAddTarifa, handleChangeTarifaUnidad, handleDeleteDetalle }}></TableTarifaOld> */}
      {isModalTarifaVisible ? (
        <ModalCotizacionTarifa
          modals={{ handleShowTarifaModal }}
          endPoints={endPoints}
          solicitud={data.selSolicitud}
          data={data}
        />
      ) : null}
    </>
  );
};
