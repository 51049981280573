import { TableClienteTbody } from './TableClienteTbody';
import { TableClienteThead } from './TableClienteThead';

export const TableCliente = (props) => {
  return (
    <table className="mt-3 w-full table-auto text-sm">
      <TableClienteThead />
      <TableClienteTbody data={props.data} />
    </table>
  );
};
