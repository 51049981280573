import { CheckCircleIcon } from '@heroicons/react/24/outline';

export const TableClienteThead = (props) => {
  return (
    <thead className="bg-gray-100 text-sm uppercase text-gray-700">
      <tr>
        <th
          scope="col"
          className=" whitespace-nowrap text-left ">
          C.C. Nit
        </th>

        <th
          scope="col"
          className=" whitespace-nowrap text-left">
          Nombre / Razón Social
        </th>
        <th
          scope="col"
          className="whitespace-nowrap text-left">
          Dirección
        </th>
        <th
          scope="col"
          className="text-left">
          Ciudad
        </th>
        <th
          scope="col"
          className="text-left">
          Teléfono
        </th>
      </tr>
    </thead>
  );
};
