import { TableCotizacionTarifaTbody } from './TableCotizacionTarifaTbody';
import { TableCotizacionTarifaThead } from './TableCotizacionTarifaThead';
import { TableCotizacionTarifaTfoot } from './TableCotizacionTarifaTfoot';

export const TableCotizacionTarifa = (props) => {
  const { isReadOnly } = props;

  return (
    <table className="w-full table-auto text-sm">
      <TableCotizacionTarifaThead
        isReadOnly={isReadOnly}
        modals={props.modals}
        actions={props.actions}
      />
      <TableCotizacionTarifaTbody
        isReadOnly={isReadOnly}
        data={props.data}
        actions={props.actions}
      />
      <TableCotizacionTarifaTfoot data={props.data} />
    </table>
  );
};
