import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';

import Select from 'react-select';
import { useEffect } from 'react';
import { store } from '../../../../../store';
import { ButtonTable } from '../../../../global/Styles/buttons';
import { inputsSettingsTrue, inputsSettingsFalse, inputTextAreaTrue, inputTextAreaFalse } from '../../../../global/Styles/inputs';
import { useApiClient, validateSuccess2xx } from '../../../../../clients/apiAuth';
import { ModalTitle } from '../../../../global/Styles/titles';
import Skeleton from 'react-loading-skeleton';
import { moneyFormat } from '../../../../../utils/format';
import FormStateToRedux from '../../../RFF/FormStateToRedux';

export const ModalCotizacionTarifa = (props) => {
  const { items, setItems, total, setTotal, auth } = props.data;

  const clienteId = auth.data.selCliente.TERCERO_ID;
  // const { createSolicitudDetalle } = props.endPoints;

  const storeState = store.getState();

  // const solicitudFecha = storeState.Solicitud?.selSolicitud.SOLICITUD_FECHA;

  const { get } = useApiClient();

  const [tarifaOptions, setTarifaOptions] = useState([]);
  const [contratoOptions, setContratoOptions] = useState([]);
  const [tarifaUnidadOptions, setTarifaUnidadOptions] = useState([]);

  const editMode = false;

  // const [filterTipoVehiculo, setFilterTipoVehiculo] = useState('');
  // const [filterUnidad, setFilterUnidad] = useState('');
  const [filterContrato, setFilterContrato] = useState('');
  const [tipovehiculoOptions, setTipoVehiculoOptions] = useState([]);

  const proxyTask = async (form) => {
    const withAllValues = {
      ...form,
      TERCERO_AUDITORIA: storeState.auth.data.user.TERCERO_ID,
      // SOLICITUD_VEHICULO_TIPO_ID: selSolicitudVehiculoTipo.SOLICITUD_VEHICULO_TIPO_ID,
    };

    // console.log('withAllValues: ', withAllValues);

    if (editMode) {
      // updateAsignarBono(storeState.AsignarBono.selAsignarBono.SOLICITUD_BONO_ID, form);
    } else {
      // createSolicitudDetalle(withAllValues);
      // console.log('!!! createSolicitudDetalle: ', items);

      const data = {
        ITEM_ID: `${Date.now()}-${Math.random().toString(36).slice(2, 11)}`,
        CONTRATO_ID: withAllValues.CONTRATO_ID,
        SOLICITUD_DETALLE_CANTIDAD: withAllValues.SOLICITUD_DETALLE_CANTIDAD,
        SOLICITUD_DETALLE_TOTAL: withAllValues.SOLICITUD_DETALLE_TOTAL,
        SOLICITUD_DETALLE_VALOR: withAllValues.SOLICITUD_DETALLE_VALOR,
        TARIFA_ID: withAllValues.TARIFA_ID,
        TARIFA_UNIDAD: withAllValues.TARIFA_UNIDAD,
        TERCERO_AUDITORIA: withAllValues.TERCERO_AUDITORIA,
        VEHICULO_TIPO_ID: withAllValues.VEHICULO_TIPO_ID,
        TARIFA_CONSECUTIVO: withAllValues.TARIFA_CONSECUTIVO,
        TARIFA_GRUPO: withAllValues.TARIFA_GRUPO,
        TARIFA_ITEM: withAllValues.TARIFA_ITEM,
        TARIFA_UNIDAD: withAllValues.TARIFA_UNIDAD,
        TARIFA_NOMBRE: withAllValues.TARIFA_NOMBRE,
      };

      // console.log(data);
      items.push(data);

      setItems([...items]);

      calculateTotals(items);
    }

    handleCloseModal();
  };

  const calculateTotals = (items) => {
    let total = items.reduce((acc, item) => acc + item.SOLICITUD_DETALLE_TOTAL, 0);

    setTotal(total);
  };

  const getContrato = async () => {
    try {
      const response = await get(`/contrato?TERCERO_ID=${clienteId}`);

      const lst = response.data.map((row) => ({
        label: `${row.CONTRATO_NUMERO} - ${row.OPERACION_MODO_NOMBRE}`,
        value: row.CONTRATO_ID,
      }));

      setContratoOptions(lst);
    } catch (error) {}
  };

  const getTipoVehiculo = async () => {
    const response = await get(`/vehiculoTipo`);

    const lst = response.data.map((row) => ({
      label: `${row.VEHICULO_TIPO_DESCRIPCION}`,
      value: row.VEHICULO_TIPO_ID,
    }));

    setTipoVehiculoOptions(lst);

    // //CAMBIA EL FILTRO.
    // setFilterTipoVehiculo(lst[0].value);
  };

  const getTarifaUnidadOptions = async (contratoId) => {
    const result = await get(`/tarifaUnidad?CONTRATO_ID=${contratoId}`);

    if (!validateSuccess2xx(result)) {
      const lst = result.data.map((row) => ({
        label: `Sin unidades`,
        value: 0,
      }));

      setTarifaUnidadOptions(lst);
    }

    const lst = result.data.map((row) => ({
      label: `${row.TARIFA_UNIDAD}`,
      value: row.TARIFA_UNIDAD,
    }));

    setTarifaUnidadOptions(lst);
  };

  const validate = (values) => {
    const errors = {};
    //  'SOLICITUD_BONO_CAUSADO' SOLICITUD_BONO_VALOR
    const requiredFields = ['TARIFA_ID', 'SOLICITUD_DETALLE_CANTIDAD'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Requerido';
      }
    });

    return errors;
  };

  const loadTarifaOptions = async (unidad, vehiculoTipo, contratoId) => {
    try {
      setTarifaOptions([]);

      if (!unidad || !vehiculoTipo || !contratoId) return;

      const result = await get(`/tarifa?TARIFA_UNIDAD=${unidad}&VEHICULO_TIPO_ID=${vehiculoTipo}&CONTRATO_ID=${contratoId}`);

      // if (!validateSuccess2xx(result)) return CustomToast({ result });

      console.log('result.data: ', result.data);
      const lst = result.data.map((row) => ({
        label: `${row.TARIFA_ITEM} : ${row.TARIFA_GRUPO} ${row.TARIFA_NOMBRE} ${moneyFormat(row.TARIFA_VALOR)}`,
        value: row.TARIFA_ID,
        price: row.TARIFA_VALOR,
        TARIFA_CONSECUTIVO: row.TARIFA_CONSECUTIVO,
        TARIFA_GRUPO: row.TARIFA_GRUPO,
        TARIFA_ITEM: row.TARIFA_ITEM,
        TARIFA_UNIDAD: row.TARIFA_UNIDAD,
        TARIFA_NOMBRE: row.TARIFA_NOMBRE,
      }));

      setTarifaOptions(lst);
    } catch (error) {
      // ErrorToast('Error:', error);
    }
  };

  const setTARIFA_UNIDAD = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TARIFA_UNIDAD'];
    field.change(args[0].value);

    const fieldTarifa = state.fields['TARIFA_ID'];
    fieldTarifa.change('');

    const vehiculoTipo = state.fields['VEHICULO_TIPO_ID'].lastFieldState.value;
    const contratoId = state.fields['CONTRATO_ID'].lastFieldState.value;

    console.log('vehiculoTipo, contratoId: ', vehiculoTipo, contratoId);

    loadTarifaOptions(args[0].value, vehiculoTipo, contratoId);
  };
  const setCONTRATO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['CONTRATO_ID'];
    field.change(args[0].value);

    getTarifaUnidadOptions(args[0].value);
    // loadTarifaOptions(args[0].value);
  };

  const setTARIFA_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['TARIFA_ID'];
    const fieldValor = state.fields['SOLICITUD_DETALLE_VALOR'];

    field.change(args[0].value);
    fieldValor.change(args[0].price);

    const fieldConsecutivo = state.fields['TARIFA_CONSECUTIVO'];
    fieldConsecutivo.change(args[0].TARIFA_CONSECUTIVO);

    const fieldGrupo = state.fields['TARIFA_GRUPO'];
    fieldGrupo.change(args[0].TARIFA_GRUPO);

    const fieldItem = state.fields['TARIFA_ITEM'];
    fieldItem.change(args[0].TARIFA_ITEM);

    const fieldUnidad = state.fields['TARIFA_UNIDAD'];
    fieldUnidad.change(args[0].TARIFA_UNIDAD);

    const fieldNombre = state.fields['TARIFA_NOMBRE'];
    fieldNombre.change(args[0].TARIFA_NOMBRE);
  };

  const setVEHICULO_TIPO_ID = (args, state, { setIn, changeValue }) => {
    const field = state.fields['VEHICULO_TIPO_ID'];
    // const fieldValor = state.fields['SOLICITUD_DETALLE_VALOR'];
    field.change(args[0].value);
    // fieldValor.change(args[0].price);

    const fieldTarifa = state.fields['TARIFA_ID'];
    fieldTarifa.change('');

    const unidad = state.fields['TARIFA_UNIDAD'].lastFieldState.value;
    const contratoId = state.fields['CONTRATO_ID'].lastFieldState.value;

    // console.log('vehiculoTipo, contratoId: ', vehiculoTipo, contratoId);

    loadTarifaOptions(unidad, args[0].value, contratoId);
  };

  const setSOLICITUD_DETALLE_CANTIDAD = (args, state, { setIn, changeValue }) => {
    const field = state.fields['SOLICITUD_DETALLE_CANTIDAD'];
    const fieldValor = state.fields['SOLICITUD_DETALLE_VALOR'];

    const fieldTotal = state.fields['SOLICITUD_DETALLE_TOTAL'];

    field.change(args[0].target.value);

    fieldTotal.change(args[0].target.value * fieldValor.lastFieldState.value);
  };

  const handleCloseModal = () => {
    props.modals.handleShowTarifaModal(false);
    //store.dispatch(setAsignarSelBonoInit());
  };

  useEffect(() => {
    getTipoVehiculo();
    getContrato();
    // getTarifaUnidadOptions();
  }, []);

  console.log('editMode: ', editMode);

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-2xl rounded-xl bg-white p-5  shadow-lg ">
        <ModalTitle>{editMode ? 'EDITAR' : 'NUEVO'} TARIFA ITEM</ModalTitle>
        <Form
          onSubmit={proxyTask}
          validate={editMode ? null : validate}
          initialValues={{ TARIFA_CONSECUTIVO: '', TARIFA_GRUPO: '', TARIFA_ITEM: '', TARIFA_UNIDAD: '' }}
          mutators={{
            setTARIFA_UNIDAD,
            setTARIFA_ID,
            setSOLICITUD_DETALLE_CANTIDAD,
            setCONTRATO_ID,
            setVEHICULO_TIPO_ID,
          }}
          subscription={{ submitting: true, pristine: true }}>
          {({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <FormStateToRedux form="frmAddTarifa" />
              <div className="">
                <div className="flex-auto justify-center p-5 text-left">
                  <div className="flex space-x-4">
                    <div className="mt-1 flex w-1/2 flex-col">
                      <Field name="TARIFA_CONSECUTIVO">
                        {({ input, meta }) => (
                          <input
                            {...input}
                            type="hidden"
                          />
                        )}
                      </Field>
                      <Field name="TARIFA_GRUPO">
                        {({ input, meta }) => (
                          <input
                            {...input}
                            type="hidden"
                          />
                        )}
                      </Field>
                      <Field name="TARIFA_ITEM">
                        {({ input, meta }) => (
                          <input
                            {...input}
                            type="hidden"
                          />
                        )}
                      </Field>
                      <Field name="TARIFA_UNIDAD">
                        {({ input, meta }) => (
                          <input
                            {...input}
                            type="hidden"
                          />
                        )}
                      </Field>
                      <Field name="TARIFA_NOMBRE">
                        {({ input, meta }) => (
                          <input
                            {...input}
                            type="hidden"
                          />
                        )}
                      </Field>
                    </div>
                    <div className="mt-1 flex w-1/2 flex-col">
                      <p className="text-sm text-gray-800">Contrato</p>
                      <Field name="CONTRATO_ID">
                        {({ input, meta }) => (
                          <>
                            {contratoOptions && (
                              <Select
                                options={contratoOptions}
                                // defaultValue={contratoOptions[0]}
                                name="selectContratoOptions"
                                onChange={form.mutators.setCONTRATO_ID}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    marginTop: 6,
                                    borderColor: meta.error && meta.touched ? 'red' : null,
                                  }),
                                }}
                              />
                            )}
                            <input
                              {...input}
                              type="hidden"
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <div className="mt-1 flex w-1/2 flex-col">
                      <p className="text-sm text-gray-800">Tipo de vehículo</p>
                      <Field name="VEHICULO_TIPO_ID">
                        {({ input, meta }) => (
                          <>
                            {tipovehiculoOptions && (
                              <Select
                                options={tipovehiculoOptions}
                                // defaultValue={tipovehiculoOptions[0]}
                                name="selecttipovehiculoOptions"
                                onChange={form.mutators.setVEHICULO_TIPO_ID}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    marginTop: 6,
                                    borderColor: meta.error && meta.touched ? 'red' : null,
                                  }),
                                }}
                              />
                            )}
                            <input
                              {...input}
                              type="hidden"
                            />
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="mt-1 flex w-1/2 flex-col">
                      <p className="text-sm text-gray-800">Unidad</p>
                      <Field name="TARIFA_UNIDAD">
                        {({ input, meta }) => (
                          <>
                            {tarifaUnidadOptions && (
                              <Select
                                options={tarifaUnidadOptions}
                                // defaultValue={tarifaUnidadOptions[0]}
                                name="selectTarifaUnidadOptions"
                                onChange={form.mutators.setTARIFA_UNIDAD}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    marginTop: 6,
                                    borderColor: meta.error && meta.touched ? 'red' : null,
                                  }),
                                }}
                              />
                            )}
                            <input
                              {...input}
                              type="hidden"
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="flex flex-col ">
                    <p className="text-sm text-gray-800">Tarifa</p>

                    <Field name="TARIFA_ID">
                      {({ input, meta }) => (
                        <>
                          {tarifaOptions && (
                            <Select
                              options={tarifaOptions}
                              // defaultValue={tarifaOptions[0]}
                              name="selectTarifaUnidadOptions"
                              onChange={form.mutators.setTARIFA_ID}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  marginTop: 6,
                                  borderColor: meta.error && meta.touched ? 'red' : null,
                                }),
                              }}
                            />
                          )}
                          <input
                            {...input}
                            type="hidden"
                          />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="flex space-x-4">
                    <div className="mt-1 w-1/3">
                      <Field name="SOLICITUD_DETALLE_CANTIDAD">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Cantidad</p>
                            <input
                              {...input}
                              type="number"
                              placeholder="Cantidad"
                              onChange={form.mutators.setSOLICITUD_DETALLE_CANTIDAD}
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="mt-1 w-1/3">
                      <Field name="SOLICITUD_DETALLE_VALOR">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Valor</p>
                            <input
                              {...input}
                              type="number"
                              placeholder="Valor"
                              readOnly
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                    <div className="mt-1 w-1/3">
                      <Field name="SOLICITUD_DETALLE_TOTAL">
                        {({ input, meta }) => (
                          <>
                            <p className="text-sm text-gray-800">Total</p>
                            <input
                              {...input}
                              readOnly
                              type="number"
                              placeholder="Total"
                              className={meta.error && meta.touched ? `${inputsSettingsFalse}` : `${inputsSettingsTrue}`}
                            />
                            {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>

                  {/* <Field name="SOLICITUD_BONO_OBSERVACION">
                    {({ input, meta }) => (
                      <>
                        <p className="text-sm text-gray-800">Observación</p>
                        <textarea
                          {...input}
                          rows={6}
                          className={meta.error && meta.touched ? `${inputTextAreaFalse}` : `${inputTextAreaTrue} `}
                        />
                        
                      </>
                    )}
                  </Field> */}
                </div>
                <div className="md:block mx-16 mt-2 space-x-4 text-center">
                  <ButtonTable onClick={() => handleCloseModal()}>Cancelar</ButtonTable>
                  {/* disabled={submitting || pristine} */}
                  <ButtonTable type="submit">{editMode ? 'Actualizar' : 'Agregar'}</ButtonTable>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};
