import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useApiClient, validateSuccess2xx } from '../../clients/apiAuth';

import { CustomToast, ErrorToast, OkToast } from '../../components/specific/Toast';

import {
  setSelSolicitud,
  setSelSolicitudLog,
  setSolicitud,
  setSolicitudInit,
  setSelAsignarBono,
  setAsignarSelBonoInit,
  setTipoVehiculoInit,
} from '../../actions';
import { store } from '../../store';

import { CheckParameter } from '../../utils/security';
import ViewCotizacion from './ViewCotizacion';

const Anticipos = (props) => {
  const [loadingTables, setLoadingTables] = useState(false);

  const [isModalCotizacionVisible, setIsModalCotizacionVisible] = useState(false);
  const [isModalAprobarVisible, setIsModalAprobarVisible] = useState(false);

  const [idSolicitud, setIdSolicitud] = useState('');
  // const [estadoAprobado, setEstadoAprobado] = useState(1);

  const [selectedTab, setSelectedTab] = useState(1);

  const [solicitudResult, setSolicitudResult] = useState([]);
  const [cotizacionData, setCotizacionData] = useState({});
  const [vehiculoTipoOptions, setVehiculoTipoOptions] = useState([]);
  // const [vehiculoOptions, setVehiculoOptions] = useState([]);
  const [trailerOptions, setTrailerOptions] = useState([]);
  const [conductorOptions, setConductorOptions] = useState([]);

  const [aparejadorOptions, setAparejadorOptions] = useState([]);

  const [selCotizacion, setSelCotizacion] = useState({});

  const [tipoSolicitud, setTipoSolicitud] = useState(1);

  // const selSolicitudVehiculoTipoID = props.tipoVehiculo.selTipoVehiculo?.pivot?.SOLICITUD_VEHICULO_TIPO_ID;
  const selSolicitudVehiculoTipoID = props.tipoVehiculo.selTipoVehiculo?.SOLICITUD_VEHICULO_TIPO_ID;

  const { get, post, put, DELETE } = useApiClient();

  const [currentPage, setCurrentPage] = useState(
    props.Solicitud.pagination === null || props.Solicitud.pagination === undefined ? 1 : props.Solicitud.pagination.currentPage
  );
  const handleNextPage = (page) => {
    const totalPages = props.Solicitud.pagination?.totalPages || 0;

    if (totalPages === 0) {
      setCurrentPage(1);
      return;
    }

    if (currentPage === totalPages) return;
    setCurrentPage(page ? page : currentPage + 1);
    getSolicitud(page ? page : currentPage + 1);
  };

  const handlePreviusPage = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
    getSolicitud(currentPage - 1);
  };

  const getSolicitud = async (pageInd = currentPage) => {
    console.log('selectedTab: ', selectedTab);

    const estadoMap = {
      1: 0,
      2: 1,
      3: 2,
    };

    const estado = estadoMap[selectedTab];
    setLoadingTables(true);
    try {
      const result = await get(`/cotizacion?page=${pageInd}&ESTADO_ID=${estado}`);
      setLoadingTables(false);

      // if (!validateSuccess2xx(result)) return CustomToast({ result });
      if (!validateSuccess2xx(result)) return;

      // store.dispatch(setSolicitud(result));

      setCotizacionData(result);
    } catch (error) {
      // console.log('Error:', error);
      ErrorToast('Error:', error);
    }
  };

  const createCotizacion = async (form) => {
    // console.log('!!! form: ', form);

    const addExtraFields = {
      TERCERO_ID: props.auth.data.user.TERCERO_ID,
      CLIENTE_ID: props.auth.data.selCliente.TERCERO_ID,
      ...form,
    };

    try {
      const result = await post(
        `/cotizacion`,
        {
          json: JSON.stringify(addExtraFields),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      getSolicitud();

      OkToast({ result, message: 'Enviado...' });
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const approveCotizacion = async (form) => {
    console.log('!!! form: ', form);

    const addExtraFields = {
      TERCERO_ID: props.auth.data.user.TERCERO_ID,
      CLIENTE_ID: props.auth.data.selCliente.TERCERO_ID,
      ...form,
    };

    try {
      const result = await post(
        `/aprobar_cotizacion`,
        {
          json: JSON.stringify(addExtraFields),
        },
        'application/json'
      );

      if (!validateSuccess2xx(result)) return CustomToast({ result });

      //getServicios();

      OkToast({ result, message: 'Enviado...' });
      getSolicitud();
      setIsModalAprobarVisible(false);
    } catch (error) {
      ErrorToast('Error:', error);
    }
  };

  const handleTabClick = (tabID) => {
    setLoadingTables(true);
    store.dispatch(setSolicitudInit());
    store.dispatch(setTipoVehiculoInit());
    setSelectedTab(tabID);
    setCurrentPage(1);
  };

  const handleNewCotizacion = () => {
    // console.log('handleNewCotizacion: ', handleNewCotizacion);
    setIsModalCotizacionVisible(true);
  };

  const handleAprobar = (item) => {
    console.log('handleAprobar: ', item);
    setSelCotizacion(item);
    setIsModalAprobarVisible(true);
  };

  const handlePDF = async (item) => {
    console.log('handlePDF: ', item);

    try {
      const result = await get(`/documento_cotizacion?DOCUMENTO_ID=${item.DOCUMENTO_ID}&format=pdf`, 'application/pdf');

      if (result.type !== 'application/pdf') throw new Error('Documento no disponible...');

      const file = new Blob([result], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      ErrorToast('Error:', error.message);
    }
  };

  useEffect(() => {
    // store.dispatch(setSolicitudInit());
    // store.dispatch(setTipoVehiculoInit());
    getSolicitud();
  }, [selectedTab]);

  const solicitud = props.Solicitud;
  const auth = props.auth;

  return (
    <ViewCotizacion
      endPoint={{
        createCotizacion,
        approveCotizacion,
      }}
      modales={{
        isModalCotizacionVisible,
        setIsModalCotizacionVisible,
        isModalAprobarVisible,
        setIsModalAprobarVisible,
      }}
      data={{
        idSolicitud,
        vehiculoTipoOptions,
        trailerOptions,
        conductorOptions,
        aparejadorOptions,
        solicitud,
        auth,

        tipoSolicitud,
        selectedTab,
        loadingTables,
        tipoVehiculo: props.tipoVehiculo,

        cotizacionData,
        selCotizacion,
      }}
      actions={{
        handleTabClick,
        handleNewCotizacion,
        handleAprobar,
        handlePDF,
      }}
      pager={{ handleNextPage, handlePreviusPage }}
    />
  );
};

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    tipoVehiculo: state.tipoVehiculo,
    Solicitud: state.Solicitud,
  };
};

export default connect(mapStateToProps)(Anticipos);
