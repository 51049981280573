import React from 'react';

const TableCotizacionThead = (props) => {
  return (
    <thead className="  sticky top-0 z-10 bg-gray-100  text-sm  uppercase  text-gray-700 ">
      <tr>
        <th className="w-2 "></th>
        <th
          scope="col"
          className="whitespace-nowrap px-2  text-left ">
          Cliente
        </th>
        <th
          scope="col"
          className="whitespace-nowrap px-2  text-left ">
          C. Int
        </th>

        <th
          scope="col"
          className="px-2  text-right">
          Total
        </th>
        <th
          scope="col"
          className="px-2   text-left">
          Contenido
        </th>
        <th
          scope="col"
          className="px-2   text-right">
          Fecha
        </th>
        <th
          scope="col"
          className="px-2   ">
          Usuario
        </th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
  );
};

//export default ViewProjects;

export default TableCotizacionThead;
