import React, { useState, useEffect } from 'react';
import { ButtonTable } from '../../../../global/Styles/buttons';

import { connect } from 'react-redux';

import { useApiClient, validateSuccess2xx } from '../../../../../clients/apiAuth';
import { CustomToast, OkToast } from '../../../Toast';

import { ModalTitle, TitlesTables } from '../../../../global/Styles/titles';
import toast from 'react-hot-toast';

import { TableVehiculos } from '../ModalSeguimiento/TableVehiculos';

import { PanelCotizacionTarifa } from '../ModalTarifa/PanelCotizacionTarifa';
import { TableCliente } from './TableCliente';
import { Form, Field } from 'react-final-form';
import { inputTextAreaFalse, inputTextAreaTrue } from '../../../../global/Styles/inputs';

const ModalCotizacion = (props) => {
  const { setModalEjecucion, setIsModalCotizacionVisible } = props;

  const { createCotizacion, getSolicitudDetalle } = props.endPoint;
  const { handleEditBonoAsignado, handleDeleteBonoAsignado, setBonoAsignado, handleChangeTarifaUnidad, createSolicitudDetalle, handleDeleteDetalle } =
    props.actions;

  const { auth } = props.data;

  const [parametros, setParametros] = useState({});
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);

  const { get } = useApiClient();

  const handleAddTarifa = (SOLICITUD_VEHICULO_TIPO_ID, TARIFA_ID, SOLICITUD_DETALLE_CANTIDAD) => {
    const frm = {
      SOLICITUD_VEHICULO_TIPO_ID: SOLICITUD_VEHICULO_TIPO_ID,
      TARIFA_ID: TARIFA_ID,
      SOLICITUD_DETALLE_CANTIDAD: SOLICITUD_DETALLE_CANTIDAD,
    };

    createSolicitudDetalle(frm);
  };

  const getParametros = async () => {
    const response = await get(`/parametro?MODE=COTIZACION`);
    //

    setParametros(response.data);
  };

  useEffect(() => {
    getParametros();
  }, []);

  const onTarifaTap = () => {
    getSolicitudDetalle(props.tipoVehiculo.selTipoVehiculo?.pivot?.SOLICITUD_VEHICULO_TIPO_ID);
    // setActiveTab('tarifa');
  };

  const handleCreateCotizacion = async (form) => {
    // console.log('*** createCotizacion: ', form);

    await createCotizacion(form);

    setIsModalCotizacionVisible(false);
  };

  return (
    <div
      className="min-w-screen animated fadeIn faster outline-none  focus:outline-none  fixed inset-0 left-0 top-0 z-50 flex h-screen items-center justify-center overflow-y-scroll bg-cover bg-center bg-no-repeat"
      id="modal-id">
      <div className="fixed inset-0 z-0 bg-black opacity-50"></div>
      <div className="relative mx-auto my-auto w-full max-w-6xl rounded-xl bg-white p-5  shadow-lg ">
        <TitlesTables>NUEVA COTIZACIÓN - CLIENTE</TitlesTables>
        <TableCliente data={auth.data} />
        <hr />
        <TitlesTables>Parámetros</TitlesTables>

        <FormFields data={{ parametros, items, setIsModalCotizacionVisible, handleCreateCotizacion }} />

        <PanelCotizacionTarifa
          handleAddTarifa={handleAddTarifa}
          handleChangeTarifaUnidad={handleChangeTarifaUnidad}
          handleDeleteDetalle={handleDeleteDetalle}
          data={{ items, setItems, total, setTotal, auth }}
          setIsModalCotizacionVisible={setIsModalCotizacionVisible}
        />
        {/* <div className="w-full space-x-4">
          <div className="md:block mx-16 mt-2 space-x-4 text-center">
            <ButtonTable onClick={handleCerrarClick}>Cerrar</ButtonTable>
          </div>
        </div> */}
      </div>
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  const requiredFields = [];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Requerido';
    }
  });

  console.log('validate errors: ', errors);
  return errors;
};

const getParametrosValues = (parametros) => {
  const p41 = parametros.find((p) => p.PARAMETRO_ID === 41);
  const p42 = parametros.find((p) => p.PARAMETRO_ID === 42);

  return { p41, p42 };
};

const FormFields = ({ data }) => {
  if (!data.parametros || Object.keys(data.parametros).length === 0) {
    return null;
  }

  const { p41, p42 } = getParametrosValues(data.parametros);

  const items = data.items;
  const handleCreateCotizacion = data.handleCreateCotizacion;

  const setIsModalCotizacionVisible = data.setIsModalCotizacionVisible;

  const editMode = false;
  return (
    <>
      <Form
        onSubmit={handleCreateCotizacion}
        validate={validate}
        initialValues={
          editMode
            ? {}
            : {
                COTIZACION_SALUDO: p41.PARAMETRO_VALOR,
                COTIZACION_NOTA: p42.PARAMETRO_VALOR,
                items: items,
              }
        }
        mutators={{}}
        subscription={{ submitting: true, pristine: true }}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex space-x-4">
              <div className="mt-1 w-1/2">
                <Field name="COTIZACION_SALUDO">
                  {({ input, meta }) => (
                    <>
                      <p className="text-sm text-gray-800">Cordial Saludo</p>
                      <textarea
                        {...input}
                        placeholder="Saludo..."
                        className={(meta.error && meta.touched) || pristine ? `${inputTextAreaFalse}` : `${inputTextAreaTrue}`}
                      />
                      {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                    </>
                  )}
                </Field>
              </div>
              <div className="mt-1 w-1/2">
                <Field name="COTIZACION_NOTA">
                  {({ input, meta }) => (
                    <>
                      <p className="text-sm text-gray-800">Nota</p>
                      <textarea
                        {...input}
                        placeholder="Nota..."
                        className={meta.error && meta.touched ? `${inputTextAreaFalse}` : `${inputTextAreaTrue}`}
                      />
                      {/* PENDIENTE PARA AGREGAR TOOLTIPS CON LOS ERRORES */}
                    </>
                  )}
                </Field>
              </div>
            </div>
            <div className=" md:block mt-2 space-x-4 text-right">
              {items.length > 0 && (
                <ButtonTable
                  type="submit"
                  disabled={submitting || pristine}>
                  {editMode ? 'Actualizar' : 'Continuar'}
                </ButtonTable>
              )}

              <ButtonTable
                type="button"
                onClick={() => {
                  setIsModalCotizacionVisible(false);
                }}>
                Cancelar
              </ButtonTable>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};

export default ModalCotizacion;
