import { moneyFormat } from '../../../../../utils/format';

export const TableCotizacionTarifaTfoot = ({ data }) => {
  const solicitudTotal = Number(data.total) || 0;
  // const solicitudVehiculoTotal = Number(data[0]?.SOLICITUD_VEHICULO_TOTAL) || 0;

  // const hasDifference = solicitudTotal !== solicitudVehiculoTotal;

  return (
    <tfoot className=" text-sm uppercase text-gray-700">
      <tr>
        <th
          scope="col"
          className=" whitespace-nowrap text-left "></th>
        <th
          scope="col"
          className=" whitespace-nowrap text-left"></th>
        <th
          scope="col"
          className="whitespace-nowrap text-left"></th>
        <th
          scope="col"
          className="whitespace-nowrap text-right"></th>
        <th
          scope="col"
          className="whitespace-nowrap bg-gray-100 text-right">
          Total
        </th>
        <th
          scope="col"
          className="whitespace-nowrap bg-gray-100 text-right">
          {moneyFormat(solicitudTotal)}
        </th>

        <th
          scope="col"
          className="w-6 cursor-pointer bg-gray-100 hover:text-green-600"></th>
      </tr>
    </tfoot>
  );
};
